import axios from "axios";
import Swal from "sweetalert2";
// import listResponse from "./mock/getDeviceList.json";
// import trackResponse from "./mock/getTrackInfo.json";
// import deviceDetailResponse from "./mock/getDeviceDetail.json";

const CryptoJS = require("crypto-js");

// account and password
const account = process.env.VUE_APP_ACCOUNT;
const password = process.env.VUE_APP_PASSWORD;

// API endpoints
const authEndpoint = process.env.VUE_APP_AUTH_ENDPOINT;
const deviceListEndpoint = process.env.VUE_APP_DEVICE_LIST_ENDPOINT;
const trackInfoEndpoint = process.env.VUE_APP_TRACK_INFO_ENDPOINT;
const deviceDetalEndpoint = process.env.VUE_APP_DEVICE_DETAIL_ENDPOINT;

// Keys for localStorage
const TOKEN_KEY = "protrack_token";
const TOKEN_EXPIRE_KEY = "protrack_token_expire_time";

// Function to authenticate and get access token
export const getAuthen = async () => {
  try {
    const currentTime = new Date();

    // Check if a token is stored and still valid
    const storedToken = localStorage.getItem(TOKEN_KEY);
    const storedTokenExpireTime = localStorage.getItem(TOKEN_EXPIRE_KEY);

    if (storedToken && storedTokenExpireTime && currentTime < parseInt(storedTokenExpireTime)) {
      console.log('Token is still valid');
      return storedToken;
    }

    // Generate timestamp and signature
    var d = new Date();
    const timestamp = Math.round(d.getTime() / 1000);
    const passwordHash = CryptoJS.MD5(password).toString();
    const signature = CryptoJS.MD5(passwordHash + timestamp).toString();

    // Send request to auth endpoint
    const query = `?time=${timestamp}&account=${account}&signature=${signature}`;
    const res = await protrackApi(authEndpoint + query, "GET", null);
    

    if (!res) {
      console.error("No response from authentication endpoint");
      return;
    }
    
    const { data: { record: { access_token, expires_in } } } = res;

    // Save the token and expiration time to localStorage (expires_in is in seconds)
    localStorage.setItem(TOKEN_KEY, access_token);
    localStorage.setItem(TOKEN_EXPIRE_KEY, currentTime + (expires_in * 1000)); // Store in milliseconds

    console.log('Token is expired, get new token');

    return access_token;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const getDeviceDetail = async (imei) => {
  try {
    // Get access token
    const accessToken = await getAuthen();
    // Send request to device list endpoint
    const query = `?access_token=${accessToken}&imeis=${imei}`;
    const response = await protrackApi(deviceDetalEndpoint + query, "GET", null);

    // Return the device list
    return response.data.record;
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

// Function to get track info
export const getTrackInfo = async (imeis) => {
  try {
    // Convert imeis array to string
    const imeisString = imeis.join(",");
    // Send request to track info endpoint

    const accessToken = await getAuthen();

    const loading = Swal.fire({
      toast: true,
      title: "Loading...",
      position: "top-end",
      showConfirmButton: false,
      timer: 0,
      didOpen: () => {
        Swal.showLoading();
      }
    })

    const query = `?access_token=${accessToken}&imeis=${imeisString}`;
    const response = await protrackApi(trackInfoEndpoint + query, "GET", null);

    loading.close();


    // Return the track info
    return response.data.record;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to call sequence
export const getDeviceInfos = async (imeis) => {
  try {
    // Get access token
    const accessToken = await getAuthen();
    // Get track info
    const response = await getTrackInfo(
      accessToken,
      Array.isArray(imeis) ? imeis : [imeis]
    );
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to get devices
export const getDevices = async () => {
  try {
    const accessToken = await getAuthen();

    // Send request to device list endpoint
    const query = `?access_token=${accessToken}`;
    const response = await protrackApi(deviceListEndpoint + query, "GET", null);
    
    // Return the device list
    return response.data.record;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

async function protrackApi(path, method, data) {
  // const baseURL = 'http://0.0.0.0:8080/protrack'
  const baseURL = 'https://infinity-backend.braiven.com/protrack'

  const res = await axios.post(baseURL, {
    method,
    path,
    data
  }).catch((error) => {
    console.error("An error occurred:", error);
  })

  return res;
}